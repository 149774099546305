<template>
  <div class="bottom-header" @click="handleClickOutside">
    <div class="container">
      <div >
        <div class="row">
        <div class="col-sm-3 col-lg-2 col-6">
          <router-link style="outline: none;" to="/">
            <img
              width="142px"
              height="49px"
              class="logo"
              src="@/assets/img/global/Logo.webp"
              alt="logo"
            />
            <img class="logo1" src="@/assets/img/global/Logo1.svg" alt="logo" />
          </router-link>
        </div>
        <div class="col-sm-9 col-lg-7 menu_cols d-none d-lg-flex align-items-center">
          <ul class="links-list">
            <li v-for="item in list" :key="item.id">
              <router-link :to="item.url" v-if="!item.sons" :target="item.target">
                {{ this.$i18n.locale == "ar" ? item.titleAr : item.titleEn }}
              </router-link>
              <div class="dropdown" v-else>
                <router-link
                  class="dropdown-btn"
                  :to="item.sons[0].url"
                  :target="item.sons[0].target"
                >
                  {{ this.$i18n.locale == "ar" ? item.titleAr : item.titleEn }}
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0914 0L7 5.14365L1.90859 0L0 1.92818L7 9L14 1.92818L12.0914 0Z"
                      fill="white"
                    />
                  </svg>
                </router-link>
                <ul class="dropdown-lists">
                  <li v-for="j in item.sons" :key="j.id">
                    <router-link
                      :to="j.url"
                      :target="j.target"
                      v-if="j.target == '_self'"
                    >
                      {{ this.$i18n.locale == "ar" ? j.titleAr : j.titleEn }}
                    </router-link>
                    <a :href="j.url" :target="j.target" v-else>
                      {{ this.$i18n.locale == "ar" ? j.titleAr : j.titleEn }}
                    </a>
                    <span></span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-sm-9 col-lg-3 d-none d-lg-flex align-items-center">
          <ul class="links-list links-list-not-after justify-content-end">
            <li
              @click="changeLanuage(this.$i18n.locale == 'ar' ? 'en' : 'ar')"
              class="nav-item dropdown dropdown-notifications langs"
              v-if="isBlueHeader"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="text-white" style="margin-inline-end: 6px">
                  {{ this.$i18n.locale == "ar" ? "En" : "ع" }}
                </span>
                <i class="fa fa-globe text-white"></i>
                <!-- <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="m-0"
                >
                  <path
                    d="M14.015 13.5815C13.794 12.9015 13.1565 12.4 12.4 12.4H11.55V9.85C11.55 9.62457 11.4604 9.40837 11.301 9.24896C11.1416 9.08955 10.9254 9 10.7 9H5.6V7.3H7.3C7.52543 7.3 7.74163 7.21045 7.90104 7.05104C8.06045 6.89163 8.15 6.67543 8.15 6.45V4.75H9.85C10.3009 4.75 10.7333 4.57089 11.0521 4.25208C11.3709 3.93327 11.55 3.50087 11.55 3.05V2.7015C12.567 3.11151 13.4695 3.76185 14.1803 4.59686C14.891 5.43187 15.3888 6.42667 15.6311 7.4961C15.8734 8.56554 15.853 9.67777 15.5715 10.7376C15.2901 11.7974 14.756 12.7732 14.015 13.5815ZM8.15 15.7405C4.7925 15.324 2.2 12.468 2.2 9C2.2 8.473 2.268 7.963 2.3785 7.4785L6.45 11.55V12.4C6.45 12.8509 6.62911 13.2833 6.94792 13.6021C7.26673 13.9209 7.69913 14.1 8.15 14.1M9 0.5C7.88376 0.5 6.77846 0.719859 5.74719 1.14702C4.71592 1.57419 3.77889 2.20029 2.98959 2.98959C1.39553 4.58365 0.5 6.74566 0.5 9C0.5 11.2543 1.39553 13.4163 2.98959 15.0104C3.77889 15.7997 4.71592 16.4258 5.74719 16.853C6.77846 17.2801 7.88376 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 7.88376 17.2801 6.77846 16.853 5.74719C16.4258 4.71592 15.7997 3.77889 15.0104 2.98959C14.2211 2.20029 13.2841 1.57419 12.2528 1.14702C11.2215 0.719859 10.1162 0.5 9 0.5Z"
                    fill="white"
                  />
                </svg> -->
              </a>
            </li>
            <li class="search-icon">
              <svg
                @click="searchModal = !searchModal"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>
              <div :class="'search-modal ' + (searchModal ? 'active' : '')">
                <div class="search-container">
                  <div>
                    <input
                      name="search"
                      v-model="keywords"
                      @keyup.enter="searchServices"
                    />
                    <button class="btn-main" @click="searchServices">
                      {{ this.$i18n.locale == "ar" ? `البحث` : `Search` }}
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-if="$store.state.Userdata"
              class="nav-item dropdown dropdown-notifications"
              @mouseenter="readNotifications"
              @mouseleave="markReadNotifications"
              @click=" dropdownOpen = !dropdownOpen"
            >
                <span class="count">{{ notifications.unreadCount }}</span>
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style="width: 15px; fill: #fff"
                >
                  <path
                    d="M224 0c-17.7 0-32 14.3-32 32l0 19.2C119 66 64 130.6 64 208l0 18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416l384 0c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8l0-18.8c0-77.4-55-142-128-156.8L256 32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3l-64 0-64 0c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"
                  />
                </svg> -->
                <i class="fa fa-bell"></i>
              <DropDownNotifications v-if="dropdownOpen" :notifications="notifications" />
            </li>
            <li v-if="$store.state.Userdata == ''">
              <router-link
                class="login"
                v-if="$route.path != '/login'"
                to="/login"
              >
                <BtnMain
                  :title="$t('Login')"
                  :TextColor="'#0349A8'"
                  :hovercolor="'#fff'"
                  :BackgroundColor="'#fff'"
                  :hoverBackgroundColor="'transparent'"
                  :Border="'#fff'"
                  :hoverBorder="'#fff'"
                />
              </router-link>
              <router-link class="login" v-else to="/register">
                <BtnMain
                  :title="$t('Sign Up')"
                  :TextColor="'#0349A8'"
                  :hovercolor="'#fff'"
                  :BackgroundColor="'#fff'"
                  :hoverBackgroundColor="'transparent'"
                  :Border="'#fff'"
                  :hoverBorder="'#fff'"
                />
              </router-link>
            </li>
            <li
              v-else
              class="profile-link"
              @click="showProfileMenu = !showProfileMenu"
            >
              <img
                style="max-width: 26px"
                class="user-icon"
                :src="$store.state.Userdata.image"
                alt=""
              />
              <span class="name">
                {{ $store.state.Userdata.name.split(" ")[0] }}
              </span>
              <ul class="profile-menu" :class="{ active: showProfileMenu }">
                <li v-if="company">
                  <router-link to="/profile/home">
                    {{ $t("Company Dashboard") }}
                  </router-link>
                </li>

                <li v-else>
                  <router-link to="/company-login">
                    {{ $t("Company Dashboard") }}
                  </router-link>
                </li>

                <li v-if="company">
                  <router-link to="/company-login">
                    {{ $t("Change Company") }}
                  </router-link>
                </li>

                <li @click="logOut()">
                  {{ $t("LogOut") }}
                </li>
              </ul>
            </li>
            <li></li>
          </ul>
        </div>
        <div
          class="col-6 col-sm-9 d-flex d-lg-none align-items-center justify-content-end"
        >
          <div id="mobile-menu">
            <div class="d-flex align-items-center">
              <div class="dropdown-notifications" v-if="isBlueHeader">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="m-0"
                  >
                    <path
                      d="M14.015 13.5815C13.794 12.9015 13.1565 12.4 12.4 12.4H11.55V9.85C11.55 9.62457 11.4604 9.40837 11.301 9.24896C11.1416 9.08955 10.9254 9 10.7 9H5.6V7.3H7.3C7.52543 7.3 7.74163 7.21045 7.90104 7.05104C8.06045 6.89163 8.15 6.67543 8.15 6.45V4.75H9.85C10.3009 4.75 10.7333 4.57089 11.0521 4.25208C11.3709 3.93327 11.55 3.50087 11.55 3.05V2.7015C12.567 3.11151 13.4695 3.76185 14.1803 4.59686C14.891 5.43187 15.3888 6.42667 15.6311 7.4961C15.8734 8.56554 15.853 9.67777 15.5715 10.7376C15.2901 11.7974 14.756 12.7732 14.015 13.5815ZM8.15 15.7405C4.7925 15.324 2.2 12.468 2.2 9C2.2 8.473 2.268 7.963 2.3785 7.4785L6.45 11.55V12.4C6.45 12.8509 6.62911 13.2833 6.94792 13.6021C7.26673 13.9209 7.69913 14.1 8.15 14.1M9 0.5C7.88376 0.5 6.77846 0.719859 5.74719 1.14702C4.71592 1.57419 3.77889 2.20029 2.98959 2.98959C1.39553 4.58365 0.5 6.74566 0.5 9C0.5 11.2543 1.39553 13.4163 2.98959 15.0104C3.77889 15.7997 4.71592 16.4258 5.74719 16.853C6.77846 17.2801 7.88376 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 7.88376 17.2801 6.77846 16.853 5.74719C16.4258 4.71592 15.7997 3.77889 15.0104 2.98959C14.2211 2.20029 13.2841 1.57419 12.2528 1.14702C11.2215 0.719859 10.1162 0.5 9 0.5Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <div class="search-icon">
                <svg
                  @click="searchModal = !searchModal"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>
                <div :class="'search-modal ' + (searchModal ? 'active' : '')">
                  <div class="search-container">
                    <div>
                      <input
                        name="search"
                        v-model="keywords"
                        @keyup.enter="searchServices"
                      />
                      <button class="btn-main" @click="searchServices">
                        {{ this.$i18n.locale == "ar" ? `البحث` : `Search` }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="$store.state.Userdata" class="dropdown-notifications">
                <a class="nav-link dropdown-toggle">
                  <span class="count">{{ notifications.unreadCount }}</span>
                  <i class="fa fa-bell"></i>
                </a>
                <DropDownNotifications :notifications="notifications" />
              </div>
              <div
                class="side-icon"
                style="cursor: pointer"
                @click="openSideBar = !openSideBar"
              >
                <!-- <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="4" width="24" height="2" fill="white" />
                  <rect y="11" width="24" height="2" fill="white" />
                  <rect y="18" width="24" height="2" fill="white" />
                </svg> -->
                <i class="fa fa-bars"></i>
              </div>
            </div>
            <div
              class="overlay"
              @click="openSideBar = false"
              :class="[openSideBar ? 'open' : '']"
            ></div>
            <div class="side-bar" :class="[openSideBar ? 'open' : '']">
              <div class="d-flex justify-content-between align-items-center">
                <div class="icon-bar" @click="openSideBar = !openSideBar">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6581 1.33776L6.99582 6L11.6581 10.6622C11.79 10.7941 11.8641 10.973 11.8641 11.1595C11.8641 11.3461 11.79 11.525 11.6581 11.6569C11.5262 11.7887 11.3473 11.8628 11.1608 11.8628C10.9742 11.8628 10.7953 11.7887 10.6635 11.6569L6.00121 6.99461L1.33897 11.6569C1.20707 11.7887 1.02819 11.8628 0.841661 11.8628C0.655135 11.8628 0.476248 11.7887 0.344354 11.6569C0.21246 11.525 0.138364 11.3461 0.138364 11.1595C0.138364 10.973 0.212461 10.7941 0.344355 10.6622L5.0066 6L0.344355 1.33776C0.212461 1.20586 0.138364 1.02698 0.138364 0.840451C0.138364 0.653925 0.21246 0.47504 0.344354 0.343146C0.476248 0.211252 0.655134 0.137155 0.841661 0.137154C1.02819 0.137155 1.20707 0.211252 1.33897 0.343145L6.00121 5.00539L10.6635 0.343146C10.7953 0.211252 10.9742 0.137155 11.1608 0.137155C11.3473 0.137155 11.5262 0.211252 11.6581 0.343146C11.79 0.47504 11.8641 0.653925 11.8641 0.840452C11.8641 1.02698 11.79 1.20586 11.6581 1.33776Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div class="logo">
                  <router-link to="/">
                    <img
                      width="100"
                      src="@/assets/img/global/Logo.webp"
                      alt="logo"
                    />
                  </router-link>
                </div>
              </div>
              <div class="side-links">
                <ul>
                  <li v-for="item in list" :key="item.id">
                    <router-link :to="item.url" v-if="!item.sons">
                      {{
                        this.$i18n.locale == "ar" ? item.titleAr : item.titleEn
                      }}
                    </router-link>
                    <div class="dropdown" v-else>
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{
                          this.$i18n.locale == "ar" ? item.titleAr : item.titleEn
                        }}
                      </button>
                      <ul class="dropdown-menu">
                        <li v-for="j in item.sons" :key="j.id">
                          <router-link class="dropdown-item" :to="j.url">
                            {{
                              this.$i18n.locale == "ar" ? j.titleAr : j.titleEn
                            }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li v-if="$store.state.Userdata == ''">
                    <router-link to="/login">
                      <BtnMain
                        :title="$t('Login')"
                        :TextColor="'#0349A8'"
                        :hovercolor="'#fff'"
                        :BackgroundColor="'#fff'"
                        :hoverBackgroundColor="'transparent'"
                        :Border="'#fff'"
                        :hoverBorder="'#fff'"
                      />
                    </router-link>
                  </li>
                  <li v-else>
                    <router-link to="/profile/home">
                      {{ $t("Company Dashboard") }}
                    </router-link>
                  </li>
                  <li
                    v-if="$store.state.Userdata != ''"
                    @click="logOut()"
                    class="log-out"
                  >
                    {{ $t("LogOut") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import Cookie from "cookie-universal";
import { Notifications } from "@kyvg/vue3-notification";
import axios from "axios";

const cookie = Cookie();
export default {
  props: ["isBlueHeader"],
  data() {
    return {
      dropdownOpen: false,
      keywords: "",
      searchModal: false,
      showProfileMenu: false,
      openSideBar: false,
      company: false,
      list: [
        {
          id: 1,
          titleEn: "Home",
          titleAr: "الصفحة الرئيسية",
          url: "/",
          target: "_self",
        },
        {
          id: 2,
          titleEn: "About Us",
          titleAr: "من نحن",
          url: "/",
          target: "_self",
          sons: [
            {
              id: "son1",
              titleAr: "نبذة عنا",
              titleEn: "About Us",
              url: "/about",
              target: "_self",
            },
            {
              id: "son2",
              titleAr: "ملف الشركة",
              titleEn: "Company File",
              url: "https://etmaam.com.sa/assets/front/files/profile.pdf",
              target: "_blank",
            },
            {
              id: "son3",
              titleAr: "حساباتنا البنكية",
              titleEn: "Accounts Banks",
              url: "/banks",
              target: "_self",
            },
            {
              id: "son4",
              titleAr: "سياسة الخصوصية",
              titleEn: "Privacy Policy",
              url: "/privacy-policy",
              target: "_self",
            },
            {
              id: "son5",
              titleAr: "الشروط  و الأحكام",
              titleEn: "Terms And Conditions",
              url: "/using-policy",
              target: "_self",
            },
            {
              id: "son6",
              titleAr: "الامتياز التجاري",
              titleEn: "Commercial franchise",
              url: "/commercial-franchise",
              target: "_self",
            },
            {
              id: "son7",
              titleAr: "كن شريكاً",
              titleEn: "Become a Partner",
              url: "/join-us",
              target: "_self",
            },
          ],
        },
        {
          id: 3,
          titleEn: "Services",
          titleAr: "الخدمات",
          url: "/services",
          target: "_self",
        },

        {
          id: 4,
          titleEn: "Packages",
          titleAr: "الباقات",
          url: "/packages",
          target: "_self",
        },
        {
          id: 5,
          titleEn: "Blogs",
          titleAr: "المدونة",
          url: "/blogs",
          target: "_self",
        },
        {
          id: 6,
          titleEn: "What We Offer",
          titleAr: "ماذا نقدم لكم",
          url: "",
          target: "_self",
          sons: [
            {
              id: "son1",
              titleAr: "تأسيس الشركات",
              titleEn: "Establishing companies",
              url: "/services/company-formation",
              target: "_self",
            },
            {
              id: "son2",
              titleAr: "خدمات المستثمرين",
              titleEn: "Investor services",
              url: "/services/foreign-services",
              target: "_self",
            },
            {
              id: "son3",
              titleAr: "الخدمات القانونية",
              titleEn: "Legal services",
              url: "/services/legal-services",
              target: "_self",
            },
            {
              id: "son4",
              titleAr: "برنامج حماية الأجور",
              titleEn: "Wage Protection Program",
              url: "/services/wage-protection",
              target: "_self",
            },
            {
              id: "son5",
              titleAr: "تسجيل العلامات التجارية",
              titleEn: "Trademark registration",
              url: "/services/trademark-registration",
              target: "_self",
            },
            {
              id: "son6",
              titleAr: "الخدمات الإلكترونية",
              titleEn: "Electronic services",
              url: "/services/electronic-services",
              target: "_self",
            },
          ],
        },
        // {
        //   id: 7,
        //   titleEn: "Join Us",
        //   titleAr: "انضم إلينا",
        //   url: "/join-us",
        //    target: "_self",
        // },
        {
          id: 7,
          titleEn: "Help Center",
          titleAr: "مركز المساعدة",
          url: "",
          target: "_self",
          sons: [
            {
              id: "son1",
              titleEn: "Contact",
              titleAr: "اتصل بنا",
              url: "/contact",
              target: "_self",
            },
            {
              id: "son2",
              titleAr: "الأسئلة الشائعة",
              titleEn: "FAQ",
              url: "/faqs",
              target: "_self",
            },
            {
              id: "son3",
              titleAr: "الوظائف",
              titleEn: "Careers",
              url: "/jobs",
              target: "_self",
            },
            {
              id: "son3",
              titleAr: "مركز التحميلات",
              titleEn: "Downloads Center",
              url: "/downloads-center",
              target: "_self",
            },
            // {
            //   id: "son4",
            //   titleAr: "الامتياز التجاري",
            //   titleEn: "Commercial franchise",
            //   url: "/commercial-franchise",
            // },
          ],
        },
      ],
      notifications: {},
    };
  },
  components: {
    BtnMain: defineAsyncComponent(() =>
      import("@/components/Global/BtnMain.vue")
    ),
    DropDownNotifications: defineAsyncComponent(() =>
      import("@/components/Notifications/DropDownNotifications.vue")
    ),
  },
  methods: {
    handleClickOutside(event) {
      // Check if the clicked target is not the profile link or the menu
      const profileLink = this.$el.querySelector(".profile-link");
      const profileMenu = this.$el.querySelector(".profile-menu");
      if (
        profileLink &&
        !profileLink.contains(event.target) &&
        !profileMenu.contains(event.target)
      ) {
        this.showProfileMenu = false;
      }
    },
    searchServices() {
      const route = `/services?q=${this.keywords}`;
      this.keywords = "";
      this.searchModal = false;
      this.$router.push(route);
    },
    changeLanuage(lang) {
      this.$store.commit("SET_Loader", true);
      this.$store.commit("SET_LANG", lang);
      axios
        .post(
          `/user/change-language`,
          { lang: lang },
          {
            headers: {
              Authorization: `Bearer ${cookie.get("Token")}`,
            },
          }
        )
        .then((res) => {
          this.$i18n.locale = lang;
          cookie.set("lang", lang);
          this.$router.go(0);
        });
    },
    logOut() {
      cookie.remove("Token");
      cookie.remove("Userdata");
      cookie.remove("Companydata");
      cookie.remove("CompanyToken");
      this.$router.go();
    },
    readNotifications() {
      if (this.notifications.unreadCount > 0)
        this.$store.dispatch("ReadNotifications");
    },
    markReadNotifications() {
      if (this.notifications.unreadCount > 0) {
        this.notifications.unreadCount = 0;
        this.notifications.list.map((item) => {
          item.is_read = 1;
        });
      }
    },
  },
  watch: {
    $route(to, from) {
      this.openSideBar = false;
    },
  },
  mounted() {
    if (cookie.get("CompanyToken") != null) this.company = true;

    this.$store.dispatch("GetNotifications").then((r) => {
      this.notifications = this.$store.state.notifications;
    });

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
